<app-propriety-wrapper [label] = "'main-page.orders.description' | translate">
  <p class = "mr-4">{{ description }}</p>
</app-propriety-wrapper>



<div class = "col-span-2 mt-2">
  <app-propriety-wrapper [label] = "'main-page.orders.service-keywords' | translate">
    <div class = "flex flex-wrap gap-2">
      <div *ngFor = "let keyword of keywords">
        <div class="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-primary-300 text-primary-600 rounded-lg">
          <p>{{ keyword }}</p>
        </div>
      </div>
    </div>
  </app-propriety-wrapper>
</div>

<div class = "w-full my-4">
	<div class = "col-span-1">
		<app-propriety-wrapper [label] = "'main-page.orders.service-type' | translate">
			<div *ngFor = "let type of types()">
				<p class = "mr-4">{{type}}</p>
			</div>
		</app-propriety-wrapper>
	</div>

	<div class = "col-span-2 mt-2">
		<app-propriety-wrapper
			*ngIf = "documentationURI"
			[label] = "'main-page.orders.service-documentation' | translate"
		>
			<a
				[href] = "documentationURI"
				class = "text-secondary-500 underline"
				target = "_blank"
			>{{'main-page.orders.service-documentation-link' | translate}}</a>
		</app-propriety-wrapper>
	</div>

	<div *ngIf="eulaUri" class = "col-span-2 mt-2">
		<app-propriety-wrapper
			
			[label]="'main-page.orders.eula-documentation' | translate"
		>
			<a
				[href]="eulaUri"
				class="text-secondary-500 underline"
				target="_blank"
			>{{'main-page.orders.eula-documentation-link' | translate}}</a>
		</app-propriety-wrapper>
	</div>

  <div *ngIf = "versionNumber" class = "col-span-1 mt-2">
		<app-propriety-wrapper
			[label] = "'main-page.orders.versionNumber' | translate"
			[textData] = "versionNumber.toString()"
		>
		</app-propriety-wrapper>
	</div>

	<div *ngIf = "legalConstraints" class = "col-span-1 mt-2">
		<app-propriety-wrapper
			[label] = "'main-page.orders.legalConstraints' | translate"
			[textData] = "legalConstraints"
		>
		</app-propriety-wrapper>
	</div>
	<div *ngIf="unitPrice">

		<div *ngIf="unitPrice.timeArea">
			<app-propriety-wrapper [label] = "'main-page.orders.unitPrice.unitPrice' | translate">
				<div class="inline-flex">
					<h2 class = "ml-1" *ngIf="orderFee !== 0">
						{{ orderFee + " + "}} {{ unitPrice.timeArea + " € "}} {{ measureUnitsTime }} {{ "main-page.orders.measureUnits.area.per" | translate}} {{aioScale}} {{ measureUnitsArea }}
					</h2>
					<h2 class = "ml-1" *ngIf="orderFee === 0">
						{{ unitPrice.timeArea + " € "}} {{ measureUnitsTime }} {{ "main-page.orders.measureUnits.area.per" | translate}} {{aioScale}} {{ measureUnitsArea }}
					</h2>
				</div>
			</app-propriety-wrapper>
		</div>
	
		<div *ngIf="unitPrice.area && !unitPrice.time">
			<app-propriety-wrapper [label] = "'main-page.orders.unitPrice.unitPrice' | translate">
				<div class="inline-flex">
					<h2 class = "ml-1">{{ orderFee + " + "}} {{ unitPrice.area + " €"}}  {{ "main-page.orders.measureUnits.area.per" | translate}} {{aioScale}} {{ measureUnitsArea }}</h2>
				</div>
			</app-propriety-wrapper>
		</div>
	
		<div *ngIf="!unitPrice.area && unitPrice.time">
			<app-propriety-wrapper [label] = "'main-page.orders.unitPrice.unitPrice' | translate">
				<div class="inline-flex">
					<h2 class = "ml-1"> {{ orderFee + " + "}} {{ unitPrice.time + " €"}} {{ measureUnitsTime }}</h2>
				</div>
			</app-propriety-wrapper>
		</div>
	</div>

	<div *ngIf="customRequestPrice">
		<app-propriety-wrapper [label] = "'placeholders.service.customPrice' | translate">
			<p>{{ customRequestPrice + " €"}}</p>
		</app-propriety-wrapper>
	</div>

  <div *ngIf = "provider?.organizationName" class = "col-span-2 mt-2">
		<app-propriety-wrapper
			[label] = "'main-page.orders.service-provider' | translate"
			[textData] = "provider?.organizationName"
			[organizationLogoURI] = "provider?.organizationLogoURI"
		></app-propriety-wrapper>
	</div>
  <div class="mt-2" *ngIf="samplesURIs && samplesURIs.length > 0">
    <app-propriety-wrapper [label]="'main-page.orders.service-samples' | translate">
      <div class="flex flex-wrap gap-2">
        <div *ngFor="let sample of samplesURIs; index as idx">
          <a href="{{ sample }}" class="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-secondary-200 text-secondary-400 rounded-lg" download>
            <p>Sample {{idx+1}}</p>
            <fa-icon *ngIf="faDownload" [icon]="faDownload" class="ml-2"></fa-icon>
          </a>
        </div>
      </div>
    </app-propriety-wrapper>
  </div>
</div>
<hr class = "separator my-6" />
<app-propriety-wrapper [label]="('main-page.orders.evaluations' | translate) + ' ('+ totalEvaluation + ')'">
  <app-evaluations [(total)]="totalEvaluation"></app-evaluations>
</app-propriety-wrapper>
