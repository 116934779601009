<div class="grid grid-cols-12 gap-4">

  <div
    class="
    col-span-12"
  >
    <app-header
      [label]="'pages-headers.marketplace' | translate"
      [hasSearchBar]="true"
      [overLabel]="'pages-headers.pages-names.marketplace' | translate"
      (search)="onPerPageChange()"
      (clear)="onPerPageChange()"
      photo="assets/img/backgrounds/services.jpg"
    ></app-header>

    <app-pagination
      classes="px-4"
      [isLoading]="!(services$ | async)"
      (currentPageChange)="onPageChange()"
      (perPageChange)="onPerPageChange()"
    ></app-pagination>

    <div
      *ngIf="services$ | async; else serviceLoading"
      [@queryAnimation]="isShowFilters ? 'openFilter' : 'closedFilter'"
      class="min-h-[80vh] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 px-4"
    >
      <ng-container
        *ngFor="let service of services$ | async"
        class="col-span-8"
      >
        <app-service-catalogue-card
          class=""
          (onCardClick)="onCardClick(service.identifier)"
          [description]="service.description | truncate : 100"
          [service]="service"
        ></app-service-catalogue-card>
      </ng-container>
    </div>
    <ng-template #serviceLoading>
      <app-loading
        label="{{ 'actions.loading.services' | translate }}"
      ></app-loading>
    </ng-template>

    <app-pagination
      *ngIf="pageTotalResults > 0"
      [isLoading]="!(services$ | async)"
      classes="px-4"
      (currentPageChange)="onPageChange()"
      (perPageChange)="onPerPageChange()"
    ></app-pagination>
  </div>
</div>
