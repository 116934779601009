import { Component } from '@angular/core';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { NgxPermissionsService } from "ngx-permissions";
import { take } from "rxjs/operators";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ecomi';

  constructor(
      private oidcSecurityService: OidcSecurityService,
      private permissionsService: NgxPermissionsService,
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService
        .checkAuth()
        .pipe(take(1))
        .subscribe((isAuthenticated) => {
          const { userData } = isAuthenticated;
          if (userData?.userRole) {
            this.permissionsService.loadPermissions([userData.userRole]);
          }
        });
  }
}
