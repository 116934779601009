export const environment = {
  production: true,
  staging: false,
  apiUrl: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '684e50d5-c637-4c2a-ad31-a621bbce9d51',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '8c29db56-2cd0-4fa4-b9d6-84c2f15749c6',
  paypal_clientID: 'ASdJ0ZIiMTct1KdvO5DCrFZeyCGu61wz5ZnzfczHnBvU37R1hTfMFJrF2SvEeonFJB2mDSbAGfSv1Qw0',
  //? External links
  externalLinks: {
    deimos: 'https://elecnor-deimos.com',
    esa: 'https://www.esa.int',
    vito: 'https://remotesensing.vito.be',
    nova: 'https://www.novasbe.unl.pt',
    terraDue: 'https://www.terradue.com/portal',
    dhi: 'https://www.dhi-gras.com',
    vandersat: 'https://vandersat.com',
    bdb: 'https://www.bdb.be',
    soilessentials: 'https://www.soilessentials.com',
    st: 'https://www.stcorp.no',
    efi: 'https://efi.int/',
    certh: 'https://www.iti.gr/iti/index.html',
    forest_design: 'https://www.forestdesign.ro/',
    junta_de_castilla_y_leon: 'https://www.jcyl.es/',
    metsakeskus:'https://www.metsakeskus.fi/',
    support:
      'https://service4eo.atlassian.net/servicedesk/customer/portal/10',
    twitter:'https://twitter.com/NextLand_EO',
    linkedin:'https://www.linkedin.com/company/nextland-eo/',
    requestAreaUrl:'https://service4eo.atlassian.net/servicedesk/customer/portal/10/group/49/create/150',
    youtube:'https://www.youtube.com/channel/UCScyB2KfSrD7FAPOzLbViKQ'
  },
  cms: {
    url: 'https://cms4eo.services4eo.com',
    api: 'https://cms4eo.services4eo.com/api',
    website: 'nxtl',
  },
  downloadAllHelp:{
    url:'https://en.wikipedia.org/wiki/Comparison_of_download_managers'
  },
  geoportalUrl: 'http://geoportal.services4eo.com/home',
  outputFilterPattern: '',
  analyticsID: 'G-Z6GD2RK710'
};
