import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MeasureUnits, ServiceProvider, Type, UnitPrice } from '@core/models';

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent implements OnInit, OnChanges {
  @Input() description?: string;
  @Input() created?: Date;
  @Input() provider: ServiceProvider | undefined;
  @Input() type?: Type;
  @Input() keywords?: string[];
  @Input() samplesURIs?: string[];
  @Input() documentationURI?: string;
  @Input() legalConstraints?: string;
  @Input() versionNumber?: number;
  @Input() unitPrice?: UnitPrice;
  @Input() measureUnitsArea?: string;
  @Input() measureUnitsTime?: string;
  @Input() customRequestPrice?: string;
  @Input() orderFee?: number;
  @Input() aioScale?: number;
  @Input() eulaUri?: string;
  
  totalEvaluation: number = 0;
  faDownload: IconDefinition = faDownload;

  constructor() {
  }

  ngOnInit(): void {
  }

  types(): string[] | undefined {
    return this.type?.toString().split(',').map((type) => type.trim());
  }

  ngOnChanges(): void {}
}
