import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CataloguePayload, IOrderJob, IOrderPaginationResponse } from '@core';
import { shareReplay, take } from 'rxjs/operators';
import { serializeParams } from 'shared';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyServicesService {
  serviceURL = `${environment.apiUrl}/v1/my-services`;

  constructor(private httpService: HttpClient) {}

  getAllPublished$(page: number, perPage: number) {
    let params = `?${serializeParams({
      page: page,
      perPage: perPage,
    })}`;

    return this.httpService
      .get<CataloguePayload>(`${this.serviceURL}/published${params}`)
      .pipe(take(1), shareReplay(1));
  }

  getAllUnpublished$(page: number, perPage: number) {
    let params = `?${serializeParams({
      page: page,
      perPage: perPage,
    })}`;

    return this.httpService
      .get<CataloguePayload>(`${this.serviceURL}/unpublished${params}`)
      .pipe(take(1));
  }

  getOrdersByServiceIdentifier(serviceId: string) {
    return this.httpService
      .get<IOrderPaginationResponse>(
        `${this.serviceURL}/published/${serviceId}/orders?unpaged=true`
      )
      .pipe(take(1), shareReplay(1));
  }

  getOrderJobs(orderId: number) {
    return this.httpService
      .get<IOrderJob[]>(`${this.serviceURL}/metrics/order/${orderId}/jobs`)
      .pipe(take(1), shareReplay(1));
  }
}
