import { Injectable } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { EventOption, ServicesFilters, UrlService } from "@core";
import { Subject } from 'rxjs';
import { isEmpty, serializeParams } from 'shared/helpers';
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public filters: ServicesFilters = {};
  public categories: string[] = [];
  public filterChange$ = new Subject<{type: string, params?: string}>();

  constructor(
    private urlService: UrlService,
    private activatedRoute: ActivatedRoute,
  ) {}

  public isEmpty = () => isEmpty(this.filters);

  public onCategoryChange(checked: boolean, value: string) {
    if (!checked) this.categories.splice(this.categories.indexOf(value), 1);
    else this.categories.push(value);

    const categories = this.categories.join(',');

    this.onFilterChange({ id: 'categories', value: categories });

    return this.filters
  }

  public onSearch( type: string,value?: string) {
    if(value){
      this.filters = {...this.filters, [type]:value}
      this.onFilterChange({id: type, value: this.filters[type]})
    }else if(!value|| value.length === 0){
      this.filters[type] = null
      this.onFilterChange({id: type, value:null})
    }
    return this.filters
  }

  public onFilterChange(target: EventOption, objectIndex?: string) {
    this.filters[target.id] = target.value;
    if (!this.filters[target.id]) delete this.filters[target.id];
    this.handleFilterChange();
  }

  private handleFilterChange() {
    const queryParam = serializeParams(this.filters);
    this.filterChange$.next({type: "update", params: queryParam});
  }

  onFilterClear() {
    this.filters = {};
    this.filterChange$.next({type: "clear"});

  }
}
