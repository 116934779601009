import { IPaginationResponse } from './pagination';
import { TransactionPayload } from './price';

export type Order = {
  id: number;
  status: OrderStatus;
  subscription: boolean;
  serviceId: string;
  serviceType: string;
  serviceSubtype: string;
  fileType: string;
  username: string;
  userUuid: string;
  aoi: string;
  createdAt: Date;
  updatedAt: Date;
  beginAt: Date;
  endAt: Date;
  timestamps: string[];
  jobs: IJob[];
  inputs: OrderInputs[];
  paymentTransaction: TransactionPayload;
  inputFileTypes: string[];
  isSubscription: boolean;
  timeStep?: number;
  newOutputs?: boolean;
};

export enum OrderStatus {
  CREATED = "CREATED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
  EMPTY = "EMPTY",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
  SUBSCRIPTION_ACTIVE = "SUBSCRIPTION_ACTIVE"
}

export type OrderStates = "CREATED" | "PENDING" | "APPROVED" | "FINISHED" | "ERROR" | "EMPTY" | "PAYMENT_PENDING" | "PAYMENT_PROCESSING" | "SUBSCRIPTION_ACTIVE"

export enum JobStatus {
  STARTED = 'STARTED',
  ACCEPTED = 'ACCEPTED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export type JobStates = 'STARTED' | 'ACCEPTED' | 'FINISHED' | 'ERROR';

export interface IJob {
  id: number;
  chainJobId: string;
  title: string;
  status: JobStatus;
  service: string;
  aoi: string;
  created: Date;
  begin: Date;
  end: Date;
  attempts: number;
  outputs: IJobOutput[];
}

interface IJobOutput {
  jobId: number;
  productIdentifier: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  layers: Layers;
}

export type Layer = {
  title: string;
  geoServerLayer: string;
  geoServerURI: string;
  downloadURI: string;
  wmsParams: any;
};

type Layers = {
  [key: string]: Layer[]
}

export interface INewOrder {
  beginAt: string | null;
  endAt: string | null;
  aoi: string | null;
  serviceId?: string | null;
  inputs: OrderExtraInput[] | null;
  isSubscription?: boolean;
  timeStep?: number
}

export interface IPreOrder {
  order: INewOrder;
  orderType: string;
}

export type OrderExtraInput = {
  identifier: string;
  value: string;
};

export interface IOrderPaginationResponse extends IPaginationResponse {
  rows: Order[];
}

export interface IOrderJob {
  id: number,
  chainJobId: string,
  status: JobStatus,
  service: string,
  createdAt: Date,
  updatedAt: Date,
  attempts: number,
  outputs: JobOutput[],
}

export type JobOutput = {
  jobId: number,
  productIdentifier: string,
  created: Date,
  type?: string,
  geoserverURI?: string,
  downloadURI?: string,
  layers: any,
  wmsStyle?: string,
  timestamps:string[],
}

export type ListOfOrders = Array<Order>;

export type OrderInputs = {
  id: number,
  identifier: string,
  dataType: string,
  value: string
}

export interface OrderPermission {
  allowedAccess: boolean;
  allowedOrder: boolean;
}

export interface ProductMetadata {
  name: string;
  description: string;
  notes: string;
  mission: string;
  class: string;
  period: {
    startAt: string;
    stopAt: string;
  }
  version: string;
  source: {
    creator: string;
    creatorVersion: string;
    createdAt: string;
  }
  footprint: string;
  equipment: {
    platform: {
      shortName: string;
      serialIdentifier: string;
    }
    sensor: {
      sensorType: string;
    }
    acquisition: {
      orbitNumber: number;
      lastOrbitNumber: number;
      orbitDirection: string;
    }
  }
  result: {
    browseInformation: [
      {
        type: string;
        referenceSystemIdentifier: {
          codeSpace: string;
          value: string;
        }
      }
    ]
    product: {
      information: {
        fileName: string;
      }
    }
  }
  metadata: {
    identifier: string;
    doi: string;
    parentIdentifier: string;
    acquisitionType: string;
    productType: string;
    status: string;
    imageQualityDegradation: {
      uom: string;
      value: string;
    }
    downlinkedTo: {
      information: {
        station: string;
        acquiredAt: string;
      }
    }
    archivedIn: {
      information: {
        archivedAt: string;
      }
    }
    processing: {
      information: {
        name: string;
        processedAt: string;
      }
    }
  }
  extraFields: {
    provider: string;
    crs: string;
    dataType: string;
    variableType: string;
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    geoserverURL: string;
    username: string;
    processingTaskId: string;
  }
}
